import React from 'react';

interface DialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    content: React.ReactNode;
    actions: React.ReactNode;
  }

  export const Dialog: React.FC<DialogProps> = ({ open, onClose, title, content, actions }) => {
    if (!open) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#323232] p-6 rounded-lg max-w-md w-full">
          <h2 className="text-xl font-bold mb-4">{title}</h2>
          <div className="mb-4">{content}</div>
          <div className="flex justify-end space-x-2">{actions}</div>
        </div>
      </div>
    );
  };

export const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  ({ className, ...props }, ref) => {
    return (
      <input
        className={`w-full px-3 py-2 bg-[#323232] text-white border border-[#4a4a4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${className}`}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export const Button = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: 'default' | 'outline' }>(
  ({ className, variant = 'default', ...props }, ref) => {
    const baseStyles = 'px-4 py-2 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#262626] transition-colors';
    const variantStyles = {
      default: 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500',
      outline: 'bg-transparent text-white border border-[#4a4a4a] hover:bg-[#4a4a4a] focus:ring-[#4a4a4a]'
    };

    return (
      <button
        className={`${baseStyles} ${variantStyles[variant]} ${className}`}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`bg-[#323232] rounded-lg shadow-md overflow-hidden ${className}`}
        {...props}
      />
    );
  }
);
Card.displayName = 'Card';

export const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`p-4 ${className}`}
        {...props}
      />
    );
  }
);
CardContent.displayName = 'CardContent';