import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sidebarItems, labManagementItems } from '../utils/data';
import axios from 'axios';

interface FeedingData {
  id: number;
  food_type: string;
  grams_given: number;
  room: string;
  notes: string;
  createdAt: string;
}

const HomePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('Jobs');
  const navigate = useNavigate();
  const [feedingData, setFeedingData] = useState<FeedingData[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<FeedingData | null>(null);
  const [visibleDocs, setVisibleDocs] = useState(6); // Control the number of visible documents

  useEffect(() => {
    fetchFeedingData();
  }, []);

  const fetchFeedingData = async () => {
    try {
      const response = await axios.get<FeedingData[]>('https://feed-fish.appbrainz.com/api/feeding');
      setFeedingData(response.data);
    } catch (error) {
      console.error('Error fetching feeding data:', error);
    }
  };

  const handleNavigation = (item: string) => {
    if (item === 'Feeding') {
      navigate('/');
    }
  };

  const handleDocumentClick = (document: FeedingData) => {
    setSelectedDocument(document);
    setIsDialogOpen(true);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const loadMoreDocuments = () => {
    setVisibleDocs((prevVisibleDocs) => prevVisibleDocs + 6); // Load 6 more documents
  };

  return (
    <div className="bg-[#262626] text-[#858585] min-h-screen flex">
      <aside className="w-60 bg-[#232323] p-6">
        <div className="flex space-x-4 mb-6">
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === 'Jobs' ? 'bg-[#1C1C1E]' : ''
            }`}
            onClick={() => setSelectedTab('Jobs')}
          >
            Jobs
          </button>
          <button
            className={`px-4 py-2 rounded ${
              selectedTab === 'Calendar' ? 'bg-[#1C1C1E]' : ''
            }`}
            onClick={() => setSelectedTab('Calendar')}
          >
            Calendar
          </button>
        </div>
        {selectedTab === 'Jobs' && (
          <nav>
            <ul className="space-y-2">
              {sidebarItems.map((item, index) => (
                <li 
                  key={index} 
                  className="p-2 rounded hover:bg-[#1C1C1E] cursor-pointer"
                  onClick={() => handleNavigation(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </nav>
        )}
      </aside>

      <main className="flex-1 p-6">
      <header className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-4">
            <div className="bg-[#5D5D5D] rounded-full w-10 h-10 flex items-center justify-center">
              {/* Placeholder for user image */}
            </div>
            <div>
              <h1 className="text-xl font-semibold">Welcome John Doe</h1>
              <p className="text-sm text-gray-500">The Doe Lab</p>
            </div>
          </div>
          <button className="bg-[#1C1C1E] px-4 py-2 text-white rounded">Check In</button>
        </header>

        <div className="flex flex-col">
          <section className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Lab Management</h2>
            <div className="grid grid-cols-7">
              {labManagementItems.map((item, index) => (
                <div 
                  key={index} 
                  className="text-center cursor-pointer"
                  onClick={() => item.name === 'fish feeding' && navigate('/feeding')}
                >
                  <div className="bg-[#323232] w-32 h-20 mx-auto mb-2 rounded-md flex items-center justify-center">
                    <img src={item.icon} alt={item.name} className="w-10 h-10" />
                  </div>
                  <p className="text-xs mt-1">{item.name}</p>
                </div>
              ))}
            </div>
          </section>

          <div className="flex space-x-6">
            <section className="flex-1">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Calendar</h2>
                <div className="flex items-center space-x-2">
                  <select className="bg-[#323232] p-2 rounded text-sm">
                    <option>January</option>
                  </select>
                  <button className="p-1 text-sm">&#9664;</button>
                  <button className="p-1 text-sm">&#9654;</button>
                </div>
              </div>
              <div className="grid grid-cols-7 gap-1">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                  <div key={day} className="text-center p-2 bg-[#323232] text-xs">{day}</div>
                ))}
                {[...Array(28)].map((_, i) => (
                  <div key={i} className="bg-[#323232] p-1 rounded-sm h-20 text-xs">
                    {/* Calendar day content */}
                  </div>
                ))}
              </div>
            </section>

            <section className="w-64">
              <h2 className="text-lg font-semibold mb-4">Important Documents</h2>
              <ul className="space-y-2">
                {feedingData.slice(0, visibleDocs).map((doc) => (
                  <li
                    key={doc.id}
                    className="bg-[#323232] p-2 rounded text-xs cursor-pointer hover:bg-[#3a3a3a]"
                    onClick={() => handleDocumentClick(doc)}
                  >
                    <p className="font-semibold">{doc.food_type} Feeding Log</p>
                    <p className="text-[#858585] mt-1">{formatDate(doc.createdAt)}</p>
                  </li>
                ))}
              </ul>
              {feedingData.length > visibleDocs && (
                <button
                  className="mt-4 bg-[#1C1C1E] px-4 py-2 text-white rounded w-full"
                  onClick={loadMoreDocuments}
                >
                  More
                </button>
              )}
            </section>
          </div>
        </div>
      </main>

      {isDialogOpen && selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">{selectedDocument.food_type} Feeding Details</h3>
            <div className="space-y-2">
              <p><strong>Food Type:</strong> {selectedDocument.food_type}</p>
              <p><strong>Grams Given:</strong> {selectedDocument.grams_given}g</p>
              <p><strong>Room:</strong> {selectedDocument.room}</p>
              <p><strong>Notes:</strong> {selectedDocument.notes}</p>
              <p><strong>Created At:</strong> {formatDate(selectedDocument.createdAt)}</p>
            </div>
            <button
              className="mt-4 bg-[#1C1C1E] px-4 py-2 text-white rounded"
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
