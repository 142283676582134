import React, { useState, useEffect } from 'react';
import { foodTypes, fishTanks as initialFishTanks, nutrients, room } from '../utils/data';
import { Input, Button, Card, CardContent } from '../components/custom-components';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { nut1, nut2 } from '../utils/data';

ChartJS.register(ArcElement, Tooltip, Legend);

interface NutrientData {
  [key: string]: number;
}

const foodNutrients: { [key: string]: NutrientData } = {
  flakes: {
    crude_protein: 0.102,
    crude_fat: 0.015,
    crude_fiber: 0.009,
    moisture: 0.0036,
    ash: 0.036,
    phosphorus: 0.024,
    calcium: 0.018,
    vitamin_a: 0.0000009,
    vitamin_d3: 1.13E-8,
    vitamin_e: 0.0000603,
    ascorbic_acid: 0.000015,
  },
  pellets: {
    crude_protein: 0.023,
    crude_fat: 0.008,
    crude_fiber: 0.001,
    ash: 0.007,
  },
};

const Feeding: React.FC = () => {
  const [selectedFood, setSelectedFood] = useState<string | null>(null);
  const [selectedTanks, setSelectedTanks] = useState<string[]>([]);
  const [fishTanks, setFishTanks] = useState(initialFishTanks);
  const [notes, setNotes] = useState<string>('');
  const [isNutrientDialogOpen, setIsNutrientDialogOpen] = useState<boolean>(false);
  const [isFoodDialogOpen, setIsFoodDialogOpen] = useState<boolean>(false);
  const [isRoomDialogOpen, setIsRoomDialogOpen] = useState<boolean>(false);
  const [currentNutrient, setCurrentNutrient] = useState<{ id: string; name: string } | null>(null);
  const [nutrientData, setNutrientData] = useState<NutrientData>({});
  const [tempNutrientValue, setTempNutrientValue] = useState<string>('');
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [customFoodType, setCustomFoodType] = useState<string>('');
  const [displayedFoodTypes, setDisplayedFoodTypes] = useState(foodTypes);
  const [newRoomName, setNewRoomName] = useState<string>('');
  const [totalFoodAmount, setTotalFoodAmount] = useState<number>(0);
  const [isAmountDialogOpen, setIsAmountDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [selectedNutrient, setSelectedNutrient] = useState<string | null>(null);
  const [otherAmount, setOtherAmount] = useState<number | null>(null);

  const handleSaveFeedingLog = async () => {
    if (!selectedFood || selectedTanks.length === 0 || totalFoodAmount === 0) {
      alert('Please select food type, at least one tank, and enter nutrient data.');
      return;
    }

    try {
      const response = await axios.post('https://feed-fish.appbrainz.com/api/feeding', {
        food_type: selectedFood,
        total_food_amount: totalFoodAmount,
        room: selectedTanks.join(', '), 
        notes: notes
      });

      if (response.status === 201) {
        alert('Feeding log saved successfully!');
        setSelectedFood(null);
        setSelectedTanks([]);
        setNutrientData({});
        setNotes('');
        setTotalFoodAmount(0);
        navigate('/');
      }
    } catch (error) {
      console.error('Error saving feeding log:', error);
      alert('Failed to save feeding log. Please try again.');
    }
  };

  const handleCancel = async () => {
    navigate('/');
  };

  useEffect(() => {
    const newColors = Object.keys(nutrientData).map((_, index) =>
      `hsl(${index * (360 / Object.keys(nutrientData).length)}, 70%, 50%)`
    );
    setChartColors(newColors);
  }, [nutrientData]);

  const handleFoodSelection = (foodName: string) => {
    if (foodName === 'Other' || (foodName !== 'Pellets' && foodName !== 'Flakes')) {
      setIsFoodDialogOpen(true);
    } else {
      setSelectedFood(foodName);
    }
  };

  const openNutrientDialog = (nutrient: { id: string; name: string }) => {
    setCurrentNutrient(nutrient);
    setIsNutrientDialogOpen(true);
    setTempNutrientValue('');
  };

  const handleNutrientSubmit = () => {
    if (currentNutrient && tempNutrientValue) {
      const value = parseFloat(tempNutrientValue);
      if (!isNaN(value)) {
        if (currentNutrient.id === 'other') {
          setOtherAmount(value);
        } else {
          setNutrientData((prev) => ({ ...prev, [currentNutrient.id]: value }));
        }
        setIsNutrientDialogOpen(false);
        setTempNutrientValue('');
      }
    }
  };

  const handleCustomFoodSubmit = () => {
    if (customFoodType.trim()) {
      setDisplayedFoodTypes(prev => 
        prev.map(food => 
          food.name === 'Other' ? { ...food, name: customFoodType.trim() } : food
        )
      );
      setSelectedFood(customFoodType.trim());
      setIsFoodDialogOpen(false);
      setCustomFoodType('');
    }
  };

  const handleAddRoom = () => {
    setIsRoomDialogOpen(true);
  };

  const handleRoomSubmit = () => {
    if (newRoomName.trim()) {
      setFishTanks(prev => [...prev, newRoomName.trim()]);
      setIsRoomDialogOpen(false);
      setNewRoomName('');
    }
  };

  const handlePredefinedAmount = (amount: number) => {
    setTotalFoodAmount(amount);
    if (amount > 0) {
      // Calculate nutrient data based on the total food amount
      const calculatedNutrients: NutrientData = {};
      const baseNutrients = selectedFood ? foodNutrients[selectedFood.toLowerCase()] : {};
      
      for (const [nutrient, baseAmount] of Object.entries(baseNutrients)) {
        calculatedNutrients[nutrient] = (baseAmount / 0.3) * amount;
      }
      
      setNutrientData(calculatedNutrients);
      setIsAmountDialogOpen(false);
    }
  }

const handleAmountSubmit = () => {
    if (totalFoodAmount > 0) {
      // Calculate nutrient data based on the total food amount
      const calculatedNutrients: NutrientData = {};
      const baseNutrients = selectedFood ? foodNutrients[selectedFood.toLowerCase()] : {};
      
      for (const [nutrient, baseAmount] of Object.entries(baseNutrients)) {
        calculatedNutrients[nutrient] = (baseAmount / 0.3) * totalFoodAmount;
      }
      
      setNutrientData(calculatedNutrients);
      setIsAmountDialogOpen(false);
    }
  };

  const handleNutrientSelection = (nutrient: string) => {
    setCurrentNutrient({ id: nutrient, name: nutrient });
    setIsNutrientDialogOpen(true);
  };

  const handleOtherClick = () => {
    setIsNutrientDialogOpen(true);
    setCurrentNutrient({ id: 'other', name: 'Other' });
  };




  const totalNutrients = Object.values(nutrientData).reduce((sum, value) => sum + value, 0);

  const pieChartData = {
    labels: Object.keys(nutrientData).map((id) => nutrients.find((n) => n.id === id)?.name || ''),
    datasets: [
      {
        data: Object.values(nutrientData).map((value) => (value / totalNutrients) * 100),
        backgroundColor: chartColors,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value.toFixed(2)}%`;
          },
        },
      },
      legend: {
        display: true,
      },
    },
  };

  const renderRooms = () => {
    return (
      <div className="flex flex-wrap  bg-[#323232] ">
        {fishTanks.map((tank) => (
          <Card
            key={tank}
            className={`w-44  h-24 flex-shrink-0 ${selectedTanks.includes(tank) ? 'bg-gray-500' : ''} cursor-pointer`}
            onClick={() => handleTankSelection(tank)}
          >
            <CardContent className=" relative p-0 ">
              <img src={room} alt={tank} className=" w-full h-full object-cover mb-1 " />
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-xl text-white font-bold  p-1 rounded">{tank}</p>
              </div>
            </CardContent>
          </Card>
        ))}
        <Card className="w-32 h-16 mt-4 flex-shrink-0  cursor-pointer bg-[]" onClick={handleAddRoom}>
          <CardContent className="flex flex-col items-center justify-center h-full">
            <span className="text-2xl font-bold">Other</span>
          </CardContent>
        </Card>
      </div>
    );
  };

  const handleTankSelection = (tank: string) => {
    setSelectedTanks([tank]); 
  };

  return (
    <div className="bg-[#262626] text-white p-6">
      <h1 className="text-6xl font-bold mb-6">Animal Feeding Log</h1>

      <div className="mb-6 flex space-x-4">
        <div className="w-1/2 mx-10">
          <h2 className="text-2xl font-bold mb-2">Food Type (Select foods used by):</h2>
          <div className="flex flex-wrap gap-4">
            {displayedFoodTypes.map((food) => (
              <Card
                key={food.name}
                className={`w-32 flex-shrink-0 ${selectedFood === food.name ? 'bg-gray-500' : 'bg-[#323232]'} cursor-pointer`}
                onClick={() => handleFoodSelection(food.name)}
              >
                <CardContent className="p-4">
                  <img src={food.icon} alt={food.name} className="w-full h-20 object-cover mb-2" />
                  <p className="text-sm text-center font-bold">{food.name}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        <div className="w-1/2">
          <h2 className="text-2xl font-bold mb-2">Nutrition Profile:</h2>
          <div className="w-full h-64">
            <Pie data={pieChartData} options={chartOptions} />
          </div>
        </div>
      </div>

      <div className="mb-6 w-1/2">
        <h2 className="text-xl w-1/2 font-bold mb-2">Amount Given:</h2>
        <div className="flex space-x-2 overflow-x-auto hide-scrollbar" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
          <Card className="w-28 flex-shrink-0 cursor-pointer" onClick={() => handlePredefinedAmount(0.02)}>
            <CardContent className="p-2 bg-[#323232]">
              <img src={nut1} alt="Nutrient 1" className="w-full h-16 object-cover" />
            </CardContent>
          </Card>
          <Card className="w-28 flex-shrink-0 cursor-pointer" onClick={() => handlePredefinedAmount(0.06)}>
            <CardContent className="p-2 bg-[#323232]">
              <img src={nut2} alt="Nutrient 2" className="w-full h-16 object-cover" />
            </CardContent>
          </Card>
          <Card className="w-28 flex-shrink-0 cursor-pointer" onClick={handleOtherClick}>
            <CardContent className="p-2 bg-[#323232]">
              <div className="w-full h-16 flex items-center justify-center">
                {otherAmount !== null ? (
                  <span className="text-lg font-bold">{otherAmount.toFixed(2)}g</span>
                ) : (
                  <span className="text-3xl">+</span>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        {/* <Button onClick={() => setIsAmountDialogOpen(true)} className="mb-4 bg-[#323232]">Enter Nutrient</Button>
        <div className="flex space-x-4 overflow-x-auto hide-scrollbar" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
          {nutrients.map((nutrient, index) => (
            <Card key={nutrient.id} className="w-32 flex-shrink-0">
              <CardContent className="p-4" style={{ backgroundColor: chartColors[index] || '#323232' }}>
                <p className="text-lg text-center font-bold">
                  {nutrientData[nutrient.id] ? `${nutrientData[nutrient.id].toFixed(3)}g` : '-'}
                </p>
              </CardContent>
            </Card>
          ))}
        </div> */}
      </div>
     
      <div className="flex mb-6 space-x-16 ">
        <div className="w-1/2 ">
          <h2 className="text-lg font-semibold mb-2">Fish Tanks Fed:</h2>
          {renderRooms()}
        </div>
        <div className="w-1/3 mx-32 j">
          <h2 className="text-xl font-semibold mb-2">Notes:</h2>
          <Input
            type="text"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full bg-[#323232] p-2 rounded"
            placeholder="Enter any additional notes here..."
          />
        </div>
      </div>
      <Button onClick={handleSaveFeedingLog}>Save Feeding Log</Button>
      <br />
      <br />
      <Button onClick={handleCancel}>Cancel</Button>

      {isNutrientDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Enter {currentNutrient?.name} Amount</h2>
            <p className="mb-4">Please enter the amount in grams.</p>
            <Input
              type="number"
              step="0.001"
              placeholder="Enter amount in grams"
              value={tempNutrientValue}
              onChange={(e) => setTempNutrientValue(e.target.value)}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsNutrientDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleNutrientSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      )}
      {isFoodDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Enter Custom Food Type</h2>
            <Input
              type="text"
              placeholder="Enter food type"
              value={customFoodType}
              onChange={(e) => setCustomFoodType(e.target.value)}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsFoodDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleCustomFoodSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      )}

{isRoomDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Enter Room Name</h2>
            <Input
              type="text"
              placeholder="Enter room name"
              value={newRoomName}
              onChange={(e) => setNewRoomName(e.target.value)}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsRoomDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleRoomSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      )}

      {isAmountDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#323232] p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Enter Total Food Amount</h2>
            <p className="mb-4">Please enter the total amount of food in grams.</p>
            <Input
              type="number"
              step="0.01"
              placeholder="Enter amount in grams"
              value={totalFoodAmount}
              onChange={(e) => setTotalFoodAmount(parseFloat(e.target.value))}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsAmountDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleAmountSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feeding;